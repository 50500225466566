<template>
  <b-table-simple class="table-eyecare" responsive>
    <thead class="neutral-200">
      <tr>
        <th>Quantidade</th>
        <th>Código e nome</th>
        <th>Lote</th>
        <th>Data de validade</th>
        <th>Valor de compra</th>
        <th>Valor de venda</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="loading">
      <tr>
        <td colspan="12" class="p-0 pt-1">
          <b-skeleton height="45px" width="100%"></b-skeleton>
        </td>
      </tr>
    </tbody>
    <tbody v-for="item in items" :key="item.id">
      <tr>
        <td>
          <b-row class="pl-4"> 
            <ArrowUp v-if="usedItems" 
              class="icon arrow" 
              @click="emitRemove(item.id)"
              v-b-tooltip.hover title="Remover item da lista"
            />
            {{ item?.item?.quantity ?? 1 }}
          </b-row>
        </td>
        <td>
          <Ellipsis>
            <strong>{{ item?.item?.barcode ?? item?.item?.code }}</strong>
            <span v-if="item.item_type.includes('Expense')">
              - {{ item?.item?.name }}
            </span>
            <span v-else>
              - {{  item?.item?.product?.name ?? item?.item?.template?.name }}
            </span>
          </Ellipsis>
        </td>
        <td>
          {{ item?.item?.batch ?? '' }}
        </td>
        <td>
          <span v-if="item?.item?.expiration_date">
            {{ parseDate(item?.item?.expiration_date) }}
          </span>
        </td>
        <td>
          <span v-if="item?.item?.purchase_price">
            {{parseNumberToMoney(item?.item?.purchase_price) }}
          </span>
          <span v-else-if="item?.item?.kit_products">
            {{
              parseNumberToMoney(item.item.kit_products.reduce((total, el) => {
                return total + el.cost ? parseFloat(el.cost) : 0
              }, 0))
            }}
          </span>
        </td>
        <td>
          <span v-if="item?.item?.sale_price">
            {{ parseNumberToMoney(item?.item?.sale_price) }}
          </span>
          <span v-else-if="item?.item?.kit_products">
            {{
              parseNumberToMoney(item.item.kit_products.reduce((total, el) => {
                return total + el.selling_cost ? parseFloat(el.selling_cost) : 0
              }, 0))
            }}
          </span>
        </td>
        <td>
          <Done v-if="item.transaction_id" 
            v-b-tooltip.hover 
            :title="usedItems ? 'Saída efetuada com sucesso!' : 'Produto voltou ao estoque com sucesso!!'"
          />
          <Trash v-else-if="usedItems" class="icon trash" 
            @click="emitMoveToWarehouse(item.id)"
            v-b-tooltip.hover title="Mover para o estoque"
          />
          <ArrowUp v-else class="icon arrow" @click="emitMoveToUsed(item.id)"/>
        </td>
      </tr>
      <tr v-for="(product, index) in item?.item?.kit_products" :key="index" class="neutral-200">
        <td class="text-center">
          {{ product.quantity }}
        </td>
        <td>
         <Ellipsis> 
          <strong v-if="product?.product?.barcode">{{ product?.product?.barcode  }} - </strong>
          <span>{{ product?.product?.name }}</span>
        </Ellipsis>
        </td>
        <td>
         <Ellipsis>{{ product?.batch?.batch ?? '' }}</Ellipsis>
        </td>
        <td>
         <Ellipsis>{{ parseDate(product?.expiration_date) }}</Ellipsis>
        </td>
        <td>
         <Ellipsis> {{ parseNumberToMoney(product?.cost) }}</Ellipsis>
        </td>
        <td colspan="2">
         <Ellipsis> {{ parseNumberToMoney(product?.selling_cost) }}</Ellipsis>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { parseDate } from '@/utils/dateHelper'

export default {
  components: {
    Trash: () => import('@/assets/icons/trash.svg'),
    ArrowUp: () => import('@/assets/icons/arrow-up2.svg'),
    Done: () => import('@/assets/icons/done.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis.vue')
  },
  props: {
    items: { type: Array, required: true },
    loading: Boolean,
    usedItems: Boolean,
  },

  methods: {
    parseNumberToMoney,
    parseDate,
    emitRemove (id) {
      this.$emit('remove', id)
    },
    emitMoveToWarehouse (id) {
      this.$emit('moveToWarehouse', id)
    },
    emitMoveToUsed (id) {
      this.$emit('moveToUsed', id)
    }
  },
}
</script>

<style lang="scss" scoped>
.icon {
  display: block;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.trash { stroke: var(--states-red-soft); }

.arrow {
  fill: var(--blue-500);
  path {
    transform: scale(1.5);
    display: block;
  }
}

td, th {
  padding: 10px !important;
}
.neutral-200 {
  background: var(--neutral-100) !important;
}
</style>